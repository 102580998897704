import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

const Content = ({ allPortfolio }) => {
  return (
    <section className="portfolio-listing-content">
      <div className="container-fluid">
        {allPortfolio?.map((item, index) => (
          <div className="portfolio-listing-content__item" key={index}>
            <div className="row">
              <div className="col-md-5">
                <div className="portfolio-listing-content__item-inner">
                  <div>
                    <h2>{item.title}</h2>
                    <address>
                      <strong>{item.acfPortfolio.city}</strong>
                      {item.acfPortfolio.address}
                    </address>
                  </div>
                  {item?.acfPortfolio?.shortDescription && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.acfPortfolio.shortDescription,
                      }}
                    />
                  )}
                  <div>
                    {item.acfPortfolio.area && (
                      <p>
                        Powierzchnia: <strong>{item.acfPortfolio.area}</strong>
                      </p>
                    )}
                    <Link to={item.uri} className="button">
                      więcej
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div
                  className="portfolio-listing-content__item-image"
                  style={{
                    backgroundImage: `url('${item?.featuredImage?.node?.localFile?.publicURL}')`,
                  }}
                ></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Content
